import React from "react";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import "../styles/navbar.css";
import "../styles/w3.css";

export default () => (
	<div className={"w3-bottom w3-black w3-opacity w3-text-white"}>
		<p className="w3-center w3-small">
			<Link to="/">Home</Link> | <Link to="/about">About</Link> |{" "}
			<Link to="/in-the-bag">In The Bag</Link> | <Link to="/tour">Tour</Link> |{" "}
			<Link to="/design">Design</Link>
			<br /> © Copyright 2020 Alex Main{" "}
			<OutboundLink
				href="https://pdga.com/player/101190"
				target="_blank"
				rel="noopener noreferrer"
			>
				PDGA 101190
			</OutboundLink>
		</p>
	</div>
);
