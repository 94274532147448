import React from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "gatsby";
import logo from "../images/logo.svg";
import "../styles/navbar.css";
import "../styles/w3.css";

export default () => (
	<div className={"w3-top"}>
		<div className="w3-row-padding">
			<div className="w3-col" style={{ maxWidth: 120, marginTop: 12 }}>
				<NavLogo link="/" src={logo} />
			</div>
			<div
				className="w3-rest w3-black w3-opacity-min w3-hide-small"
				style={{ marginTop: 12 }}
			>
				<div className={"w3-large"}>
					<NavButton link="/" name="Home" />
					<NavButton link="/about" name="About" />
					<NavButton link="/in-the-bag" name="In The Bag" />
					<NavButton link="/tour" name="Tour" />
					<NavButton link="/design" name="Design" />
				</div>
			</div>
			<Hamburger />
		</div>
	</div>
);

const NavButton = (props) => (
	<Link
		to={props.link}
		className={"w3-bar-item navbutton w3-hide-small w3-right"}
		activeClassName={
			"w3-bar-item navbutton w3-hide-small w3-right w3-text-light-blue"
		}
	>
		{props.name}
	</Link>
);

const NavLogo = (props) => (
	<Link to={props.link}>
		<img
			src={props.src}
			alt="Personal Logo"
			className={"w3-transparent logo"}
		/>
	</Link>
);

const Hamburger = () => (
	<div className="w3-hide-medium w3-hide-large w3-right w3-xlarge">
		<Menu right>
			<NavSlide link="/" name="Home" />
			<NavSlide link="/about" name="About" />
			<NavSlide link="/in-the-bag" name="In The Bag" />
			<NavSlide link="/tour" name="Tour" />
			<NavSlide link="/design" name="Design" />
		</Menu>
	</div>
);

const NavSlide = (props) => (
	<Link
		to={props.link}
		className={"bm-item w3-mobile"}
		activeClassName={"bm-item w3-text-blue"}
		activeStyle={{ borderColor: `#2196f3` }}
	>
		&nbsp;{props.name}
	</Link>
);
