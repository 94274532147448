import React from "react";
import Helmet from "react-helmet";

export default (props) => (
	<Helmet>
		<title>{props.i.title} | Alex Main</title>
		<meta charset="UTF-8" />
		<meta name="viewport" content="width=device-width, initial-scale=1" />
		<meta
			name="description"
			content="Official website of Alex Main - professional disc golf athlete PDGA #101190 and software engineering professional"
		/>
		<meta property="og:title" content={props.i.title + " | Alex Main"} />
		<meta
			property="og:description"
			content="Official website of Alex Main - professional disc golf athlete PDGA #101190 and software engineering professional"
		/>
		<meta property="og:type" content="website" />
		<meta property="og:url" content="https://alexma.in/" />
		<meta property="og:image" content="https://alexma.in/images/mett.jpg" />
		<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
		<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
		<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
		<link rel="manifest" href="/site.webmanifest" />
		<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#333333" />
		<meta name="apple-mobile-web-app-title" content="Alex Main" />
		<meta name="application-name" content="Alex Main" />
		<meta name="msapplication-TileColor" content="#333333" />
		<meta name="theme-color" content="#333333" />
	</Helmet>
);
